import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Header from '../components/header';
import Footer from '../components/footer';
import Content from '../components/content';
import Icon from '../components/icon';
import SEO from '../components/seo';

import serviceStyles from '../styles/service.module.less';

export default () => {
    const data = useStaticQuery(graphql`
        query {
            bannerImageBg: file(relativePath: { eq: "service/banner_bg.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 557) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            advantageImage: file(relativePath: { eq: "service/advantage_bg.jpg" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            question: allFile(
                filter: { relativePath: { regex: "service/question/.*/" } }
                sort: { fields: name, order: ASC }
            ) {
                nodes {
                    childImageSharp {
                        fluid(maxHeight: 800) {
                            originalName
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `);
    const imageList = data.question.nodes;

    return (
        <>
            <SEO title="服务优势" />
            <Header />
            {/* Banner */}
            <div className={serviceStyles.serviceBanner}>
                <div className={serviceStyles.bannerContent}>
                    <Content>
                        <div className={serviceStyles.bannerContentLeft}>
                            <div className={serviceStyles.bannerContentLeftTitle}>服务团队</div>
                            <p className={serviceStyles.bannerContentLeftDescription}>
                                统一规范的服务标准、全国40多家高覆盖率的
                                本地服务，打造线上线下一体化的服务流程
                            </p>
                            <p className={serviceStyles.bannerContentLeftDescription}>
                                帮您轻松上手，享受高效快捷的服务
                            </p>
                        </div>
                        {/* <div className={serviceStyles.bannerContentImg}>
                            <Img fluid={data.bannerImage.childImageSharp.fluid} />
                        </div> */}
                    </Content>
                </div>
                <Img fluid={data.bannerImageBg.childImageSharp.fluid} />
            </div>
            <br />
            <Content
                title="品质服务 · 全程陪伴"
                description="给您专业、贴心、优质的解决方案"
                titleStyle={{ fontSize: '30px' }}
                descriptionStyle={{ fontSize: '20px' }}
                className={serviceStyles.service}
            >
                <Row gutter={[32, 32]}>
                    <Col span={8} className={serviceStyles.serviceCon}>
                        <div className={serviceStyles.serviceConItem}>
                            {/* <img class={serviceStyles.serviceConItemIcon} src={serviceTelephone} alt="" /> */}
                            <Icon type="iconhuabanfuben2" />
                            <p className={serviceStyles.itemTitle}>秒速级响应</p>
                            <p className={serviceStyles.serviceConItemDescrption}>
                                拒绝漫长等待，我们将在第一时间对您的反馈进行响应
                            </p>
                        </div>
                    </Col>
                    <Col span={8} className={serviceStyles.serviceCon}>
                        <div className={serviceStyles.serviceConItem}>
                            <Icon type="iconyiduiyizhuanshukefu" />
                            <p className={serviceStyles.itemTitle}>一对一专属服务</p>
                            <p className={serviceStyles.serviceConItemDescrption}>
                                从第一次问候开始，您的专属客服将伴随您的整个使用过程
                            </p>
                        </div>
                    </Col>
                    <Col span={8} className={serviceStyles.serviceCon}>
                        <div className={serviceStyles.serviceConItem}>
                            <Icon type="iconhezuowoshou" />
                            <p className={serviceStyles.itemTitle}>资深服务团队</p>
                            <p className={serviceStyles.serviceConItemDescrption}>
                                3年以上资深行业经验的团队为您权威解答
                            </p>
                        </div>
                    </Col>
                </Row>
            </Content>
            {/* question */}
            <Content className={serviceStyles.question}>
                <h2 className={serviceStyles.questionTitle}>购买过其他软件产品的</h2>
                <h2 className={serviceStyles.questionTitle}>你是否对下列问题感同身受？</h2>
                <div className={serviceStyles.cardList}>
                    <Row gutter={[16, 16]} className={serviceStyles.cardList}>
                        <Col span={12}>
                            {<Img fluid={imageList[0].childImageSharp.fluid} />}
                            <div className={serviceStyles.introduction}>
                                <p className={`${serviceStyles.title} ${serviceStyles.itemTitle}`}>
                                    升级付费
                                </p>
                                <p className={serviceStyles.itemDescrib}>功能升级需要付费，</p>
                                <p className={serviceStyles.itemDescrib}>
                                    不付费就无法使用新功能。
                                </p>
                            </div>
                        </Col>
                        <Col span={6}>
                            {<Img fluid={imageList[1].childImageSharp.fluid} />}
                            <div className={serviceStyles.introduction}>
                                <p className={`${serviceStyles.title} ${serviceStyles.itemTitle}`}>
                                    服务对接难
                                </p>
                                <p className={serviceStyles.itemDescrib}>成交后有问题需要解决，</p>
                                <p className={serviceStyles.itemDescrib}>但就是找不到人服务。</p>
                            </div>
                        </Col>
                        <Col span={6}>
                            {<Img fluid={imageList[2].childImageSharp.fluid} />}
                            <div className={serviceStyles.introduction}>
                                <p className={`${serviceStyles.title} ${serviceStyles.itemTitle}`}>
                                    收费不清晰
                                </p>
                                <p className={serviceStyles.itemDescrib}>联系客服解决问题时，</p>
                                <p className={serviceStyles.itemDescrib}>张口闭口就谈费用。</p>
                            </div>
                        </Col>
                        <Col span={6}>
                            {<Img fluid={imageList[3].childImageSharp.fluid} />}
                            <div className={serviceStyles.introduction}>
                                <p className={`${serviceStyles.title} ${serviceStyles.itemTitle}`}>
                                    处理问题慢
                                </p>
                                <p className={serviceStyles.itemDescrib}>每次对接的客服都不同，</p>
                                <p className={serviceStyles.itemDescrib}>问题迟迟难以得到解决。</p>
                            </div>
                        </Col>
                        <Col span={6}>
                            {<Img fluid={imageList[4].childImageSharp.fluid} />}
                            <div className={serviceStyles.introduction}>
                                <p className={`${serviceStyles.title} ${serviceStyles.itemTitle}`}>
                                    客服不专业
                                </p>
                                <p className={serviceStyles.itemDescrib}>
                                    出现问题时客服无法及时响应，
                                </p>
                                <p className={serviceStyles.itemDescrib}>严重影响企业正常运营。</p>
                            </div>
                        </Col>
                        <Col span={6}>
                            {<Img fluid={imageList[5].childImageSharp.fluid} />}
                            <div className={serviceStyles.introduction}>
                                <p className={`${serviceStyles.title} ${serviceStyles.itemTitle}`}>
                                    培训收费
                                </p>
                                <p className={serviceStyles.itemDescrib}>购买产品后期需要再次</p>
                                <p className={serviceStyles.itemDescrib}>培训时必须额外付费。</p>
                            </div>
                        </Col>
                        <Col span={6}>
                            {<Img fluid={imageList[6].childImageSharp.fluid} />}
                            <div className={serviceStyles.introduction}>
                                <p className={`${serviceStyles.title} ${serviceStyles.itemTitle}`}>
                                    数据易丢失
                                </p>
                                <p className={serviceStyles.itemDescrib}>
                                    本地安装，数据一旦出问题或
                                </p>
                                <p className={serviceStyles.itemDescrib}>
                                    服务器出问题，数据永久丢失。
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content>

            {/* slogan */}
            <div className={serviceStyles.slogan}>
                <div className={serviceStyles.sloganContent}>
                    <Content>
                        <Row gutter={[32, 32]}>
                            <div style={{ padding: '20px' }}>
                                <p className={serviceStyles.serviceThirdTitle}>
                                    在店货通上述问题都将不复存在，
                                </p>
                                <br />
                                <br />
                                <br />
                                <p className={serviceStyles.serviceSecondTitle}>因为</p>
                                <p className={serviceStyles.serviceSecondTitle}>一切只为客户满意</p>
                                <p className={serviceStyles.serviceSecondTitle}>
                                    是店货通的服务宗旨
                                </p>
                            </div>
                        </Row>
                    </Content>
                </div>
            </div>

            {/* 为您提供更好的服务 */}
            <div className={serviceStyles.serviceContent}>
                <Content
                    title="为您提供· 更好的服务内容"
                    description="专业的服务团队，全程的呵护陪伴，定会助你成长"
                    titleStyle={{ fontSize: '30px' }}
                    descriptionStyle={{ fontSize: '20px' }}
                >
                    <br />
                    <br />
                    <Row gutter={[32, 32]}>
                        <Col span={8}>
                            <div className={serviceStyles.serviceContentItem}>
                                <Icon
                                    type="iconshouye_gerenzhongxinhui"
                                    style={{ color: '#228cf7' }}
                                />
                                <p className={serviceStyles.itemDescrib}>一对一专属服务</p>
                                <p className={serviceStyles.itemBase}>
                                    不论是售后培训还是专属客服管家服务，
                                    店货通都提供专属服务，避免出现同一
                                    个咨询问题多次叙述的情况，影响问题 解决效率。
                                </p>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className={serviceStyles.serviceContentItem}>
                                <Icon type="icon24xiaoshiqiantai" style={{ color: '#228cf7' }} />
                                <p className={serviceStyles.itemDescrib}>7×24小时无休式服务</p>
                                <p className={serviceStyles.itemBase}>
                                    当有问题需要我们协助或解决时，无论什
                                    么时间都可以通过客服QQ/客服电话找到
                                    您的专属客服，避免下班时间客服无响应， 影响企业正常运作。
                                </p>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className={serviceStyles.serviceContentItem}>
                                <Icon type="iconquanbugengduo" style={{ color: '#228cf7' }} />
                                <p className={serviceStyles.itemDescrib}>多种方式服务</p>
                                <p className={serviceStyles.itemBase}>
                                    系统中的帮助中心为您提供视频教程、操作文档、常见问题解决办法
                                    ；QQ远程操作对您手把手教学；店货通在线大课堂让新员工快速上手；客服专线随时等候您的差遣。
                                </p>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div className={serviceStyles.serviceContentItem}>
                                <Icon type="iconshujuanquanbaozhang" style={{ color: '#228cf7' }} />
                                <p className={serviceStyles.itemDescrib}>数据安全保障</p>
                                <p className={serviceStyles.itemBase}>
                                    采用阿里云服务器，所有数据存在云端，给你银行级别的安全保障；自动备份，不再惧怕各种数据丢失问题，简单操作即可恢复。
                                </p>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className={serviceStyles.serviceContentItem}>
                                <Icon type="iconshengji" style={{ color: '#228cf7' }} />
                                <p className={serviceStyles.itemDescrib}>产品免费升级</p>
                                <p className={serviceStyles.itemBase}>
                                    店货通的产品一直在不断的迭代、更新、升级，而我们的客户可以免费享受这项服务，不花钱就能轻松跟上科技发展的步伐，享受新技术带来的便利。
                                </p>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className={serviceStyles.serviceContentItem}>
                                <Icon type="iconbeifen" style={{ color: '#228cf7' }} />
                                <p className={serviceStyles.itemDescrib}>用户随时备份</p>
                                <p className={serviceStyles.itemBase}>
                                    除系统自动备份数据外，用户也可自行定期备份，一键导出，方便快捷。
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </div>

            {/* 服务内容 */}
            <article className={serviceStyles.serviceGoodsContent}>
                <Content
                    title="我们的服务优势"
                    description="完善而细致的服务体系优势，一切只为用心为您服务"
                    titleStyle={{ fontSize: '30px' }}
                    descriptionStyle={{ fontSize: '20px' }}
                >
                    <br />
                    <Row gutter={[32, 32]}>
                        <Col span={6}>
                            <div className={serviceStyles.serviceContentItem}>
                                <Icon type="iconzhuanzhuyongxin" style={{ color: '#f766ae' }} />
                                <p className={serviceStyles.itemDescrib}>专注用心</p>
                                <p className={serviceStyles.itemBase}>6年专注电商进销存</p>
                                <p
                                    className={`${serviceStyles.itemBase} ${serviceStyles.itemCenter}`}
                                >
                                    解决电商订单管理方案
                                </p>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={serviceStyles.serviceContentItem}>
                                <Icon type="iconlijiekehu" style={{ color: '#2cb985' }} />
                                <p className={serviceStyles.itemDescrib}>理解客户</p>
                                <p className={serviceStyles.itemBase}>伴随中国企业从平凡走向</p>
                                <p
                                    className={`${serviceStyles.itemBase} ${serviceStyles.itemCenter}`}
                                >
                                    卓越服务于大中小型企业
                                </p>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={serviceStyles.serviceContentItem}>
                                <Icon type="iconzhuanyeguwen" style={{ color: '#ffc350' }} />
                                <p className={serviceStyles.itemDescrib}>专业顾问</p>
                                <p className={serviceStyles.itemBase}>专业的技术、实施顾问确保</p>
                                <p
                                    className={`${serviceStyles.itemBase} ${serviceStyles.itemCenter}`}
                                >
                                    项目成功交付，续约率高达95%
                                </p>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className={serviceStyles.serviceContentItem}>
                                <Icon type="iconweibiaoti-2" style={{ color: '#ed6060' }} />
                                <p className={serviceStyles.itemDescrib}>贴身服务</p>
                                <p className={serviceStyles.itemBase}>咨询+培训+软件实施</p>
                                <p
                                    className={`${serviceStyles.itemBase} ${serviceStyles.itemCenter}`}
                                >
                                    7*24小时五星级服务
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </article>
            <Footer />
        </>
    );
};
